import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
library.add(faPhone, faEnvelope);
dom.i2svg();
dom.watch();

import DataTable from 'datatables.net-dt';
import 'datatables.net-dt/css/dataTables.dataTables.min.css';
// import DataTable from 'datatables.net-bs5';
// import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css'
new DataTable('#products-table', {});

import Parallax from 'scroll-parallax';
var element = document.querySelector('.gallery figure img')
element.classList.remove("hidden");
new Parallax('.parallax', {center: 3, intensity: 10, offsetYBounds: 600}).init()

var analytics = require('universal-ga');
analytics.initialize('UA-57154028-7');
analytics.pageview(window.location.pathname);
